import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import * as firebase from 'firebase/app';
// import 'firebase/firestore'; wtf is this?

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showMenu = "";
  title = "b.othercooked.com";
  items: any;
  currentBoardName = "";//Administrator";
  currentBoardId = "";
  language = "en";
  history = false;
  urlData: any;
  placeId = "";
  realId = "";
  place: any = {};
  placeName = "";
  placeType = "";
  logged = null;
  password: any;
  page = "front";
  boards = [];
  currentTable: any;
  Baskets: any;// = [];
  BasketsNumbers = {};

  constructor(public db: AngularFirestore, public auth: AngularFireAuth,) {

    console.log("1456 :)");



  }


  async ngOnInit() {


    this.placeId = window.location.pathname.split('/')[1];
    console.log(this.placeId);

    if (!this.placeId) {
      this.page == "front";
      return;
    }

    if (this.placeId == "app") {
      this.page = "app";
      return;
    }

    // big else here 
    this.page = "board";

    this.db.doc('Places/' + this.placeId).ref.get().then(snap => {
      if (snap.exists) {
        var data: any = snap.data();
        this.place = data;
        this.placeName = data.Name;
        this.placeType = data.Type;
      }
    });

  }


  BoardChange(id, name) {
    this.currentBoardName = name;
    this.currentBoardId = id;
  }



  async Login() {
    console.log(this.password + "/" + this.placeId);

    if (!this.password)
      return;


    await this.db.collection('Places/' + this.placeId + '/Boards').ref
      .where("Password", "==", Md5.hashStr(this.password)).get()
      .then(async snap => {
        //console.log(snap.docs.length);

        if (snap.docs.length > 0) {
          //console.log(snap.docs[0].data());

          // sigin in with email and pass MOVE THIS TO PROPER AUTH
          this.auth.signInWithEmailAndPassword(this.placeId.toLowerCase() + "@othercooked.com", this.placeId).then(u => {
            console.log("LOGGED! " + u.user.uid);
            this.realId = u.user.uid;
          });

          var data: any = snap.docs[0].data();
          this.logged = data.Name;
          this.currentBoardName = data.Name;
          this.currentBoardId = snap.docs[0].id;

          this.BoardChange(this.currentBoardId, this.currentBoardName);

          if (this.currentBoardId == "Administrator") {
            await this.db.collection('Places/' + this.placeId + '/Boards').ref.get()
              .then(snap => {
                this.boards = [];
                for (var b of snap.docs) {
                  var bData: any = b.data();
                  this.boards.push({ id: b.id, name: bData.Name, color: bData.Color });
                }
              });

            this.Baskets = await this.db.collection('Places/' + this.placeId + '/Baskets', ref => ref.orderBy('Number'))
              .snapshotChanges().pipe(
                map(table => table.map(a => {
                  var jopa: any = a.payload.doc.data();
                  jopa.id = a.payload.doc.id;

                  this.BasketsNumbers[jopa.id] = jopa.Number;

                  // https://www.materialui.co/colors
                  if (jopa.Status == "Call") jopa.Color = "#FF8A65";//"warn";
                  if (jopa.Status == "Free") jopa.Color = "#E0E0E0";//"link"; // 300 opacity
                  if (jopa.Status == "Reserved") jopa.Color = "#FFD54F";//"accent";

                  return jopa;
                })
                )
              );

          }

        }
      });

  }

  Logout() {
    this.logged = null;
    this.password = null;
    this.currentBoardName = null;
  }

  Test() {
    if (confirm("Are you sure to delete?")) {
      console.log("ok");
    }
  }

  GetUsersAmount(table) {
    if (table && table.Users)
      return Object.keys(table.Users).length;
    else return 0;
  }

  StatusChangeOnproduct(order, product, index, newStatus) {
    // Process, Ready, Done, Delete

    if (newStatus == "New") {
      return;
    }

    if (newStatus == "Delete") {
      if (confirm("Are you sure to delete " + order.Products[index].Name + "?")) {
        console.log("delete...");
        console.log("was " + order.Products[index].Price + "/" + order.Total);

        // revert amount FIRSTLY because later we delete this product
        if (order.Products[index].Quantity) {
          console.log("revert " + order.Products[index].ProductId + " of " + order.Products[index].Quantity);
          this.db.doc('Places/' + this.placeId + '/Products/' + order.Products[index].ProductId).set({
            Available: firebase.firestore.FieldValue.increment(order.Products[index].Quantity),
            Reserved: firebase.firestore.FieldValue.increment(-order.Products[index].Quantity)
          }, { merge: true });
        }

        // get product's price
        var price = order.Products[index].Price;

        // change order's total on this price
        var newTotal = order.Total - price;

        // delete product from array
        var newProducts = order.Products;
        newProducts.splice(index, 1);



        // save order in Global
        this.db.doc('Orders2/' + order.OrderId).set({
          Total: newTotal,
          NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
          Products: newProducts
        }, { merge: true });

        // basket counters
        this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId).set({
          NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
          // orders? no?
        }, { merge: true });

        return;
      }
      else {
        return;
      }
    }

    if (newStatus == "Done") {
      // basket counters
      this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId).set({
        NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        // orders? no?
      }, { merge: true });
    }



    // this product in Orders
    var newProducts = order.Products;
    newProducts[index].Status = newStatus;

    // this product in Global
    this.db.doc('Orders2/' + order.OrderId).set({
      Products: newProducts,
      Status: "Process",
      NotDoneProducts: (newStatus == "Done") ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0),
    }, { merge: true });

  }

  TableChange(t = null) {
    console.log("TABLE " + t.Name + " " + this.history);

    if (t) {
      this.currentTable = t;

      if (this.history) // show only paid (OLD!)
        this.items = this.db.collection('Orders2', ref => ref
          // .where("User.RealId", "==", this.realId) // removed because of new rules
          .where("BasketId", "==", this.currentTable.id)
          .where("Status", "in", ["Paid", "Cancelled"])
          // .orderBy('Status', 'desc')
          .orderBy('Created', 'desc')
          .limit(30)
          //.orderBy(firebase.firestore.FieldPath.documentId()) better?
        ).snapshotChanges()
          .pipe(

            map(restaurant => restaurant.map(a => {

              var jopa: any = a.payload.doc.data();
              if (jopa.Status != "Paid")  // was Done
                this.currentTable.HasOrders = true;

              return jopa;

            }))
          );
      else // show only work ones
        this.items = this.db.collection('Orders2', ref => ref
          //.where("User.RealId", "==", this.realId)
          .where("BasketId", "==", this.currentTable.id)
          // .where("Created", "<=", new Date('2021-' + request.query.m + '-31'))  // test!
          // .where("OrderId", ">", "")
          .where("Status", "not-in", ["Paid", "Cancelled"])
          // .orderBy('OrderId', 'desc')
          .orderBy('Status', 'desc')
          .orderBy('Created', 'desc')
          //.orderBy(firebase.firestore.FieldPath.documentId()) better?
        ).snapshotChanges()
          .pipe(

            map(results => results.sort((a, b) => {

              var jopaA: any = a.payload.doc.data();
              var jopaB: any = b.payload.doc.data();
              // return new Date(jopaA.Created).getTime() - new Date(jopaB.Created).getTime();
              return (jopaA.OrderId - jopaB.OrderId);
            })),

            map(restaurant => restaurant.map(a => {

              var jopa: any = a.payload.doc.data();
              if (jopa.Status != "Paid")  // was Done
                this.currentTable.HasOrders = true;

              // console.log(jopa.User.Name);
              // delete jopa.showMenu;

              return jopa;

            }))
          );


    }
    // else {
    //   console.log("TABLE all");

    //   this.currentTable = null;
    //   this.items = this.db.collection('Orders2', ref => ref
    //       .where("User.RealId", "==", this.realId)
    //       .orderBy('Created', 'desc')
    //     ).snapshotChanges().pipe(
    //     map(restaurant => restaurant.map(a => {

    //       var jopa : any = a.payload.doc.data();
    //       return jopa;
    //     }))
    //   );
    // }

  }


  About() {
    return alert(" \r\n\
    Build 20240328.1314 \r\n\
    Whats new: \r\n\
    - no revert for the Online orders \r\n\
    ");
  }

  GetColorOfTheBoard(id) {
    return this.boards.find(element => element.id == id).color;
  }

  async OrderPaid(order) {

    // reports need for All cases!
    order.Products.forEach(p => {

      // kassa sync
      if (p.OuterId != "Delivery" && this.placeType == "Grocery")
        this.db.collection('Places/' + this.placeId + '/Baskets/' + order.BasketId + '/Sync/').add({ "Id": p.OuterId, "Quantity": p.Quantity });

      // we did it before on order stage as I remember!?
      // this.db.doc('Places/'+this.placeId+'/Products/'+p.ProductId).set({
      //   // Available: firebase.firestore.FieldValue.increment(p.Quantity),
      //   Reserved: firebase.firestore.FieldValue.increment(-p.Quantity)
      // }, {merge: true});

      // save report (do we need separate report or just use Orders2 later?)
      var today = new Date();
      var todayDoc = today.toISOString().substring(0, 10);
      this.db.doc('Places/' + this.placeId + '/Reports/' + todayDoc).set({
        [p.OuterId]: {
          Code: p.OuterId,
          Price: p.Price,
          Quantity: firebase.firestore.FieldValue.increment(parseInt(p.Quantity)),
          VAT: p.VAT,
          Total: firebase.firestore.FieldValue.increment(parseInt(p.Quantity) * p.Price)
        }
      }, { merge: true });


    });
    // old variant
    // if (this.placeType == "Grocery") {
    //   // console.log("in");
    //   order.Products.forEach(p => {
    //     // console.log(p);
    //     if (p.OuterId != "Delivery") {
    //       this.db.collection('Places/'+this.placeId+'/Baskets/'+order.BasketId+'/Sync/').add({"Id": p.OuterId, "Quantity": p.Quantity});

    //       this.db.doc('Places/'+this.placeId+'/Products/'+p.ProductId).set({
    //         // Available: firebase.firestore.FieldValue.increment(p.Quantity),
    //         Reserved: firebase.firestore.FieldValue.increment(-p.Quantity)
    //       }, {merge: true});

    //       var today = new Date();
    //       var todayDoc = today.toISOString().substring(0, 10);
    //       this.db.doc('Places/'+this.placeId+'/Reports/' + todayDoc).set({
    //         [p.OuterId]: { 
    //           Code: p.OuterId,
    //           Price: p.Price,
    //           Quantity: firebase.firestore.FieldValue.increment(parseInt(p.Quantity)), 
    //           VAT: p.VAT,
    //           Total: firebase.firestore.FieldValue.increment(parseInt(p.Quantity) * p.Price)
    //         }
    //       }, {merge: true});

    //     }
    //   });
    // }

    // remove from table?
    //this.db.doc('Places/'+this.placeId+'/Baskets/'+order.BasketId+'/Orders/'+order.OrderId).delete();

    // remove global
    // this.db.doc('Orders/'+order.OrderId).delete();

    // change status in orders
    this.db.doc('Orders2/' + order.Id).set({
      Status: "Paid",
    }, { merge: true });

    // how to check & free basket?
    //...


    // update basket only if restaurant
    if (this.placeType == "Restaurant") {

      // --
      await this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId).set({
        NotDoneOrders: firebase.firestore.FieldValue.increment(-1),
      }, { merge: true });

      // compare and free
      await this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId).ref.get().then(async snap => {

        // if no more orders
        var data: any = snap.data();
        if (data.NotDoneOrders <= 0)

          // free table
          await this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId).set({
            Status: "Free",
            NotDoneOrders: 0,
            NotDoneProducts: 0,
            // Users: firebase.firestore.FieldValue.delete(), // <- old
          }, { merge: true });

        // remove users
        await this.db.collection('Places/' + this.placeId + '/Baskets/' + order.BasketId + '/Users').ref.get().then(snap => {
          snap.docs.forEach(doc => {
            this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId + '/Users/' + doc.id).delete();
          });
        });
      });


    }



  }

  QtyChange(event, index, product, order) {
    // console.log(event);
    if (!event || event == "") {
      console.log("empty, quit");
      return;
    }

    if (event != product.Quantity) {

      // get product's price
      var price = product.Price;//order.Products[index].Price;

      // change order's total on this price
      var newTotal = order.Total - (price * event);
      order.Products[index].Quantity = event;
      var newProducts = order.Products;
      console.log(order.Total, price * event, newTotal);
      // return;
      // return;

      // // delete product from array
      // var newProducts = order.Products;
      // newProducts.splice(index, 1);


      // put back quantity to the shop 5->4 = 1, 5>6 
      var qtyBack = product.Quantity - event;

      this.db.doc('Places/' + this.placeId + '/Orders/' + order.OrderId + '/Products/' + product.OuterId).update({
        Available: firebase.firestore.FieldValue.increment(qtyBack)
      });

      // save everywhere
      // whole order in Orders 
      this.db.doc('Places/' + this.placeId + '/Orders/' + order.OrderId).set({
        Total: newTotal,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        Products: newProducts
      }, { merge: true });

      // whole order in Table
      this.db.doc('Places/' + this.placeId + '/Baskets/' + order.BasketId + '/Orders/' + order.OrderId).set({
        Total: newTotal,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        Products: newProducts
      }, { merge: true });

      // whole order in Global
      this.db.doc('Orders/' + order.OrderId).set({
        Total: newTotal,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        Products: newProducts
      }, { merge: true });

      // console.log(product.Quantity);
      // order.Total = 
      // console.log(order.Total);

      //change quantity
      // product.Quantity = event;
    }
  }

  RecoverOrder(order, recover) {

    this.db.doc('Orders2/' + order.OrderId).set({
      Status: (recover) ? "Recovered" : "Paid"
    }, { merge: true });

  }

  async FreeTable(table) {
    // console.log(tableId);

    delete this.currentTable.HasOrders;
    // console.log(this.currentTable);

    await this.db.doc('Places/' + this.placeId + '/Baskets/' + table.id).set({
      Status: "Free",
      NotDoneOrders: 0,
      NotDoneProducts: 0,
      Users: firebase.firestore.FieldValue.delete()
    }, { merge: true });


    this.TableChange();
  }

  async CallTable(table) {
    // console.log(tableId);

    await this.db.doc('Places/' + this.placeId + '/Baskets/' + table.id).set({
      Status: "Reserved"
    }, { merge: true });

    this.currentTable.Status = "Reserved";
    this.TableChange(table);
  }

  pMenuShow(p) {
    if (this.showMenu != "" && this.showMenu != p.PID) {
      console.log("NOT ONLY ONE!");
      return;
    }
    // console.log("menu on");
    p.showMenu = true;
    this.showMenu = p.PID;
    // console.log(this.showMenu);
  }
  pMenuClose(p) {
    // console.log("menu off");
    delete p.showMenu;// = false;
    this.showMenu = "";
    // console.log(this.showMenu);
  }

  ProductDelete(order, index) {
    this.pMenuClose(order.Products[index]);

    if (confirm("Are you sure to delete " + order.Products[index].Name + "?")) {
      console.log("delete...");
      console.log("was " + order.Products[index].Price + "/" + order.Total);

      // revert amount FIRSTLY because later we delete this product
      if (order.Products[index].Quantity) {
        console.log("revert " + order.Products[index].ProductId + " of " + order.Products[index].Quantity);
        this.db.doc('Places/' + this.placeId + '/Products/' + order.Products[index].ProductId).set({
          Available: firebase.firestore.FieldValue.increment(order.Products[index].Quantity),
          Reserved: firebase.firestore.FieldValue.increment(-order.Products[index].Quantity)
        }, { merge: true });
      }

      // get product's price
      var price = order.Products[index].Price;

      // change order's total on this price
      var newTotal = order.Total - price;

      // delete product from array
      var newProducts = order.Products;
      newProducts.splice(index, 1);



      // save order in Global
      this.db.doc('Orders2/' + order.Id).set({
        Total: newTotal,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        Products: newProducts
      }, { merge: true });

      // basket counters
      // this.db.doc('Places/'+this.placeId+'/Baskets/'+order.BasketId).set({
      //   NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
      //   // orders? no?
      // }, {merge: true});

      this.CheckIsOrderDone(newProducts, order.Id);

      return;
    }
    else {
      return;
    }

  }

  ProductNextStatus(order, index, isDone) {
    // New, Process, Ready, Done


    var newStatus = "";

    if (isDone)
      newStatus = "Done";
    else {
      if (order.Products[index].Status == "New")
        newStatus = "Process";
      else if (order.Products[index].Status == "Process")
        newStatus = "Ready";
      else if (order.Products[index].Status == "Ready")
        newStatus = "Done";
    }

    this.pMenuClose(order.Products[index]);


    // if (newStatus == "Done") {
    //   // basket counters
    //   this.db.doc('Places/'+this.placeId+'/Baskets/'+order.BasketId).set({
    //     NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
    //     // orders? no?
    //   }, {merge: true});
    // }



    // this product in Orders
    var newProducts = order.Products;
    newProducts[index].Status = newStatus;

    this.CheckIsOrderDone(newProducts, order.Id);

  }


  CheckIsOrderDone(newProducts, id) {
    // check all products
    var isOrderDone = true;
    newProducts.forEach(p => {
      if (p.Status != "Done")
        isOrderDone = false;
    });
    console.log("is order done? " + isOrderDone);

    // this product in Global
    this.db.doc('Orders2/' + id).set({
      Products: newProducts,
      Status: (isOrderDone == true) ? "Done" : "Process",
      // NotDoneProducts: (newStatus == "Done") ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0),
    }, { merge: true });
  }

  ToggleHistory() {
    this.history = !this.history;
    this.TableChange(this.currentTable);
  }

  // YesReceipt(order) {

  // }
  // NoReceipt(order) {

  // }
  // EmailSave(order) {

  // }

  // OrderPaidCash(order) {

  // }
  // OrderPaidCard(order) {

  // }

}
